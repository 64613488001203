import DeviceDetector from "device-detector-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { APP_CONST } from "../../../../config/const";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { socket } from "../../../../utils/socketUtil";
import { Toaster } from "../../../../utils/toaster";
import ExchangeFundsForm from "../../../components/ExchangeFundsForm";
import { axiosService } from "../../../../utils/axiosService";
import BottomNav from "../../../../containers/BottomNav";

const SpribeLobbyPage = () => {
  const [casinoLobbyUrl, setCasinoLobbyUrl] = useState();

  useEffect(() => {
    console.log("useEffect");
    try {
      const result = axiosService.post(
        "https://dev-spribe.sportsinfo247.com/api/stageLaunchLobby",
        {
          // token: localStorage.getItem("token"),
          token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtc3RyaWQiOjEyMDg1MSwiaWF0IjoxNjg4MDI1ODM3fQ.pZsOD-Ywiwm61uFSuTL-l--hLSsjJeTLpLQtziJmhX0",
          "providerCode": "aviator",
          "gameCode": "",
          "homeUrl": ""
        }
      );
      result
        .then((res) => {
          console.log(res);
          if (!res?.data?.error) {
            return setCasinoLobbyUrl(res.data.data.lobbyUrl);
          }
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    } catch (err) {
      console.log(err);
      return;
    }
  }, []);

  return (
    <div className="live_casino_page live">
      <div className="header-bg">
        <HeaderAfterLogin />
      </div>

      {!Boolean(casinoLobbyUrl) ? (
        <div className="custom_placeholder_parent">
          <div className="custom_placeholder_child">
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
          </div>
        </div>
      ) : casinoLobbyUrl === "notAllowed" ? (
        <Navigate to="/home" />
      ) : casinoLobbyUrl === "notFound" ? (
        <Navigate to="/home" />
      ) : (
        <div className="exchange_iframe">
          <div>
            <iframe
              title="Exchange"
              src={casinoLobbyUrl}
              frameBorder="0"
              style={{
                width: "100%",
                height: "100vh",
                maxHeight: "calc(100vh - 100px)",
                // aspectRatio: "16/9",
              }}
              // scrolling="no"
              allowFullScreen={true}
            >
              Your browser doesn't support iFrames.
            </iframe>
          </div>
        </div>
      )}
      {/*  Iframe End */}

      <div>
        <BottomNav />
      </div>
    </div>
  );
};

export default SpribeLobbyPage;
